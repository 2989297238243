import { Injectable } from '@angular/core';
import * as AWS from 'aws-sdk/global';
import { CognitoIdentity, Credentials } from 'aws-sdk';
import * as aws4 from "ngx-aws4";
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AwsService {

  token:any;
  userData:any;

  constructor(private http: HttpClient){}

  authenticatefss(idToken:string){

    const cognitoIdentity = new CognitoIdentity({
      region: 'us-west-2'
    });
    
    // Parameters for getId();
    const getIdParams = {
      IdentityPoolId: 'us-west-2:b3dfda6b-efa3-4677-9135-fc3f04db2f7a',
      Logins: {
        'fss.stg.inbcu.com/fss': idToken,
        /* '<IdentityProviderName>': ... */
      }
    };
    // debugger
    
    // Get the Cognito ID
    return cognitoIdentity.getId(getIdParams).promise()
      .then((cognitoId) => {
        //console.log(cognitoId)
        var params = {
          IdentityId: cognitoId.IdentityId,
          Logins: {
            'fss.stg.inbcu.com/fss': idToken,
            /* '<IdentityProviderName>': ... */
          }
        };

        return cognitoIdentity.getOpenIdToken(params).promise();
      }).then((cognitoOpenIdToken) =>{
        //console.log(cognitoOpenIdToken)
        var params = {
          IdentityId: cognitoOpenIdToken.IdentityId, /* required */
          Logins: {
            'cognito-identity.amazonaws.com': cognitoOpenIdToken.Token
          }
        };
        return cognitoIdentity.getCredentialsForIdentity(params).promise()
      }).then((cognitoCredentials)=>{
        //console.log(cognitoCredentials)
        
        const awsCredentials = new Credentials({accessKeyId: cognitoCredentials.Credentials.AccessKeyId, secretAccessKey: cognitoCredentials.Credentials.SecretKey, sessionToken: cognitoCredentials.Credentials.SessionToken });
        awsCredentials.expireTime = cognitoCredentials.Credentials.Expiration;
        awsCredentials.expired = false;

        // //console.log(awsCredentials)
        return awsCredentials;
      });
    
  }  
}
